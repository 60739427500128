import React, { useState, useEffect } from 'react'
import moment from 'moment'

import {
  get,
  ORGS_URL,
  RESTAURANTS_URL,
  MEALSERVINGS_URL,
  MEALSERVINGLOGS_URL,
  MEALTYPES_URL,
  WASTETYPES_URL,
  COURSES_URL
} from '../../utility/api'

import HukkaSelect from '../hukkaSelect'

const Servings = () => {
  const [init, setInit] = useState(false)
	const [orgs, setOrgs] = useState([])
	const [selectedOrg, setSelectedOrg] = useState(null)
	const [restaurants, setRestaurants] = useState([])
  const [selectedRestaurant, setSelectedRestaurant] = useState(null)
  const [from, setFrom] = useState(moment().subtract(1, 'months').format('YYYY-MM-DD'))
  const [to, setTo] = useState(moment().format('YYYY-MM-DD'))
  const [servings, setServings] = useState([])
  const [logs, setLogs] = useState([])
  const [servingsReady, setServingsReady] = useState(true)
  const [logsReady, setLogsReady] = useState(true)
  const [mealTypes, setMealTypes] = useState([])
  const [wasteTypes, setWasteTypes] = useState([])
  const [courses, setCourses] = useState([])
  const [minCustomers, setMinCustomers] = useState(0)
  const [maxCustomers, setMaxCustomers] = useState(-1)
  const [minPrepared, setMinPrepared] = useState(0)
  const [maxPrepared, setMaxPrepared] = useState(-1)

  const getOrgs = async () => {
    let payload = {}
    const resp = await get(ORGS_URL, payload)

    if (resp.status === 200) {
        setOrgs(resp.data.slice()
          .sort((a, b) => {
            if (a.name.toLowerCase() > b.name.toLowerCase()) return 1
            if (a.name.toLowerCase() < b.name.toLowerCase()) return -1
            return 0
          }).map(ms => ({
          value: ms.id,
          label: ms.name
        })))
    } else {
    }
  }

  const getMealTypes = async () => {
    let payload = {}
    const resp = await get(MEALTYPES_URL, payload)

    if (resp.status === 200) {
      setMealTypes(resp.data)
    } else {
    }
  }

  const getWasteTypes = async () => {
    let payload = {}
    const resp = await get(WASTETYPES_URL, payload)

    if (resp.status === 200) {
      setWasteTypes(resp.data)
    } else {
    }
  }

  const getCourses = async () => {
    let payload = {}
    const resp = await get(COURSES_URL, payload)

    if (resp.status === 200) {
      setCourses(resp.data)
    } else {
    }
  }

  const getRestaurants = async (org) => {
    let payload = {
    	org: org
    }
    const resp = await get(RESTAURANTS_URL, payload)

    if (resp.status === 200) {
    	setRestaurants(resp.data.slice()
		  .sort((a, b) => {
		    if (a.name.toLowerCase() > b.name.toLowerCase()) return 1
		    if (a.name.toLowerCase() < b.name.toLowerCase()) return -1
		    return 0
		  }).map(ms => ({
        value: ms.id,
        label: ms.name
      })))
    } else {
    }
  }

  const getServings = async () => {
    let payload = {
    	org: selectedOrg.value,
      restaurant: selectedRestaurant.value,
      from,
      to
    }
    const resp = await get(MEALSERVINGS_URL, payload)

    if (resp.status === 200) {
      setServingsReady(true)
    	setServings(resp.data)
    } else {
    }
  }

  const getLogs = async () => {
    let payload = {
    	org: selectedOrg.value,
      restaurant: selectedRestaurant.value,
      from,
      to
    }
    const resp = await get(MEALSERVINGLOGS_URL, payload)

    if (resp.status === 200) {
      setLogsReady(true)
    	setLogs(resp.data)
    } else {
    }
  }

  useEffect(() => {
  	if (!init) {
  		setInit(true)
  		getOrgs()
      getMealTypes()
      getWasteTypes()
      getCourses()
  	}
  })

  const printServings = () => {
    let html = []

    let totals = {
      servings: 0,
      customers: 0
    }

    wasteTypes.forEach(function (wt) {
      totals[wt.name + ' - prepared'] = 0
      totals[wt.name + ' - waste'] = 0
    })

    servings.filter(s => 
      (maxCustomers > -1 ? (s.customers <= maxCustomers && s.customers >= minCustomers) : (s.customers >= minCustomers)) &&
      (maxPrepared > -1 ? (s.preparedAmountGrams <= maxPrepared && s.preparedAmountGrams >= minPrepared) : (s.preparedAmountGrams >= minPrepared)))
      .sort((a, b) => {
        if (a.servingDatetime > b.servingDatetime) return 1
        if (a.servingDatetime < b.servingDatetime) return -1
        return 0
      })
      .forEach(function (s) {
      let logsHtml = []

      if (logs && logs.length > 0) {
        logsHtml.push(<tr>
          <td><b>Food</b></td>
          <td><b>Waste type</b></td>
          <td><b>Course</b></td>
          <td><b>Prepared</b></td>
          <td><b>Waste</b></td>
        </tr>)
        totals['customers'] = totals['customers'] + s.customers
        totals['servings'] = totals['servings'] + 1
        logs.filter(l => l.mealServing === s.id).forEach(function (l) {
          let logWasteType = wasteTypes.find(wt => wt.id === l.wasteType)
          let logCourse = courses.find(c => c.id === l.course)
          if (logWasteType) {
            totals[logWasteType.name + ' - prepared'] = totals[logWasteType.name + ' - prepared'] + l.preparedAmountGrams
            totals[logWasteType.name + ' - waste'] = totals[logWasteType.name + ' - waste'] + l.wasteGrams
          } else {
            if (!totals['undefined - prepared']) {
              totals['undefined - prepared'] = 0
            }
            if (!totals['undefined - waste']) {
              totals['undefined - waste'] = 0
            }
            totals['undefined - prepared'] = totals['undefined - prepared'] + l.preparedAmountGrams
            totals['undefined - waste'] = totals['undefined - waste'] + l.wasteGrams
          }
          logsHtml.push(<tr>
            <td>{l.foodName}</td>
            <td>{logWasteType ? logWasteType.name : '-'}</td>
            <td>{logCourse ? logCourse.name : '-'}</td>
            <td>{l.preparedAmountGrams}g</td>
            <td>{l.wasteGrams}g</td>
          </tr>)
        })
      }

      html.push(<table className="servingsTable">
        <tbody>
          <tr style={{borderBottom: '2px solid #000'}}>
            <td>Date: {moment(s.servingDatetime).format('DD.MM.YYYY')}</td>
            <td>Meal type: {mealTypes.find(mt => mt.id === s.mealType).name}</td>
            <td>Customers: {s.customers}</td>
            <td>Prepared: {s.preparedAmountGrams}g</td>
            <td>Edited: {moment(s.updatedTime).format('DD.MM.YYYY HH:mm')}</td>
          </tr>
          <tr></tr>
          {logsHtml}
        </tbody>
      </table>)
    })

    let totalsHtml = []

    Object.keys(totals).forEach(function (key) {
      totalsHtml.push(<tr>
        <td style={{borderRight: '2px solid #000', borderBottom: '2px solid #000', padding: '0 10px'}}><b>{key}</b></td>
        <td style={{borderBottom: '2px solid #000', padding: '0 10px'}}><b>{totals[key]}</b></td>
      </tr>)
    })

    return (<div>
      <br />
      <br />
      <table style={{display: 'inline-block', margin: 'auto', border: '2px solid #000', borderBottom: '0'}}>
        <tbody>
          {totalsHtml}
        </tbody>
      </table>
      <br />
      <br />
      {html}
    </div>)
  }
  
  return (<div>
  	<h1 className="title is-1 is-size-3-mobile is-uppercase">Servings</h1>

    <div className="select is-large is-fullwidth hukkaselect">
      <HukkaSelect
        options={orgs}
        placeholder={'Choose organization'}
        value={selectedOrg}
        onChange={e => {
        	setSelectedOrg(e)
        	getRestaurants(e.value)
        }}
      />
    </div>

    {orgs && orgs.length > 0 && selectedOrg && (<>
      <div className="select is-large is-fullwidth hukkaselect">
        <HukkaSelect
          options={restaurants}
          placeholder={'Choose restaurant'}
          value={selectedRestaurant}
          onChange={e => {
            setSelectedRestaurant(e)
          }}
        />
      </div>
      <br />
      Servings: {servingsReady ? 'Ok' : 'Fetching...'}
      <br />
      Logs: {logsReady ? 'Ok' : 'Fetching...'}
      <br />
      <br />
      <p>Date:</p>
      <input value={from} onChange={e => setFrom(e.target.value)} /> - <input value={to} onChange={e => setTo(e.target.value)} />
      <br />
      <br />
      <input
        type="button"
        onClick={() => {
          setServings([])
          setLogs([])
          getServings()
          getLogs()
          setServingsReady(false)
          setLogsReady(false)
        }}
        value='Search'
      />
      <br />
      <br />
      <p>Filter by customer amount:</p>
      <input type="number" value={minCustomers} onChange={e => setMinCustomers(e.target.value)} /> - <input type="number" value={maxCustomers} onChange={e => setMaxCustomers(e.target.value)} />

      <p>Filter by prepared amount (grams):</p>
      <input type="number" value={minPrepared} onChange={e => setMinPrepared(e.target.value)} /> - <input type="number" value={maxPrepared} onChange={e => setMaxPrepared(e.target.value)} />
      <br />
      <br />
      
      {courses.length > 0 && wasteTypes.length > 0 && mealTypes.length > 0 && servings.length > 0 && printServings()}
    </>)}

  </div>)
}

export default Servings
